@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
  .absolute-center-y {
    @apply absolute top-1/2 -translate-y-1/2;
  }
  .absolute-center-x {
    @apply absolute left-1/2 -translate-x-1/2;
  }

  .grid-cols-1a1 {
    grid-template-columns: 1fr auto 1fr;
  }
  .grid-cols-aaa {
    grid-template-columns: auto auto auto;
  }
  .grid-rows-1a1 {
    grid-template-rows: 1fr auto 1fr;
  }
  .grid-rows-a1a {
    grid-template-rows: auto 1fr auto;
  }
  .grid-rows-1aa {
    grid-template-rows: 1fr auto auto;
  }
  .grid-rows-aa1 {
    grid-template-rows: auto auto 1fr;
  }
  .grid-rows-aaa {
    grid-template-rows: auto auto auto;
  }
  .without-scrollbar {
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  .field {
    @apply bg-white w-full border-2 outline-none border-transparent transition duration-300 rounded-[1.6rem] text-black font-semibold caret-cPurple700;
    &:disabled {
      @apply text-cGray500 border-cGray700 bg-cGray100;
      ~ .placeholder {
        @apply text-cGray500;
      }
    }
    &:focus {
      @apply border-cPurple700 placeholder:duration-300 placeholder:text-cGray500;
      ~ .placeholder {
        @apply text-1.2 top-0.4 !text-cPurple700;
      }
    }
    &.error {
      @apply border-cOrange700 text-cOrange700;
      ~ .placeholder {
        @apply !text-cOrange700;
      }
    }
  }
}

iframe {
  width: 100%;
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 10px;
  scroll-behavior: smooth;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings:
    'ss02' on,
    'ss03' on,
    'ss04' on,
    'ss05' on,
    'ss06' on,
    'ss08' on,
    'ss09' on,
    'ss11' on,
    'liga' off;
}

html,
body {
  height: 100%;
}

select {
  appearance: none;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @apply text-white;
  @apply bg-cBlack;
  min-width: 320px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

* {
  box-sizing: border-box;

  &:after {
    box-sizing: border-box;
  }

  &:before {
    box-sizing: border-box;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-variant-numeric: inherit;
  font-feature-settings: inherit;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol {
  margin: 0;
  padding: 0;
}

address {
  margin: 0;
  padding: 0;
  font-style: normal;
}

figure {
  margin: 0;
  padding: 0;
}

table {
  margin: 0;
  padding: 0;
}

b {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.06rem);
  }
}

@keyframes spinSlow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingSpin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}

#app {
  grid-template-rows: auto 1fr auto;
}

.Toastify__toast {
  font-family: inherit;
}
